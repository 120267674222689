import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/layouts/post-layout.js"
import CustomImage from '$components/CustomImage'
import Carousel from '$components/Carousel'
import ImageGrid from '$components/Article/ImageGrid'
import { graphql } from 'gatsby'
export const pageQuery = graphql`
    query GatsbyImagesAutonomousTravel {
        ...ImagesAutonomousTravel
    }
`
// Cmment
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>




<MDXTag name="h1" components={components}>{`Introduction`}</MDXTag>
<MDXTag name="p" components={components}>{`Driverless cares are coming. It is only a matter of time until autonomous driving will be the norm. In this two week course led by Jahn Göhlke (Google Design), I designed a concept for the future road trip. In the first week of user research and field interviews we generated a lot of concepts. Based on the feedback we got in the first week, we decided to go further with a service that allows users to comfortably book a holiday ride with an autonomous vehicle. It should leverage the possibilities autonomous travel and focus on longer trips.`}</MDXTag>
<MDXTag name="p" components={components}>{`Our high level goals were:`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Make trip, in current holiday excursions often times just an unpleasant step, part of the experience.`}</MDXTag>
<MDXTag name="p" components={components} parentName="blockquote">{`The vehicle should reflect our intentions in its form and interior.`}</MDXTag>
<MDXTag name="p" components={components} parentName="blockquote">{`Create a booking process that is as convenient as possible. Keep decisions at a minimum.`}</MDXTag>
</MDXTag>
<MDXTag name="h1" components={components}>{`Outcome`}</MDXTag>
<Carousel wrapper>
    <CustomImage imgSrc="attendants" description="Onboarding" />
    <CustomImage imgSrc="attendants2" description="Preferences" />
    <CustomImage imgSrc="attendants3" description="Preference deletion" />
    <CustomImage imgSrc="money" description="Budget" />
    <CustomImage imgSrc="route" description="Mood" />
    <CustomImage imgSrc="route2" description="Destination" />
    <CustomImage imgSrc="route3" description="Generated route" />
    <CustomImage imgSrc="route4" description="Alternative stops" />
    <CustomImage imgSrc="route5" description="Route intervention" />
</Carousel>
<MDXTag name="h1" components={components}>{`Emphasize`}</MDXTag>
<MDXTag name="h2" components={components}>{`Filed Interview`}</MDXTag>
<MDXTag name="p" components={components}>{`The field interview phase should turn out to be especially challenging. The generated feedback wasn’t or did not seem valuable in many cases. Since the task was to conduct a field interview in the city of Potsdam, it was hard to find people that were versed in the concepts of autonomous driving. We learned that to get feedback that genuinely reflects the hopes and aspirations of the people we were designing for, first we would need to introduce them to the aspects of the problem or rephrase the question in a way that does not directly address the issue. From the feedback that we gathered, security played a disproportionately high concern. Most subjects got reduced to this security issue, especially for parents.
From the feedback, we got from the field interviews, we decided on our further steps. The higher goals were defined.`}</MDXTag>
<MDXTag name="p" components={components}>{`[...]`}</MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  